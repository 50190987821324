import React from "react"
import styled from "styled-components"
import { Layout, Gallery } from "../components"

const GalleryPage = ({ data }) => {
  return (
    <Wrapper>
      <Layout>
        <Gallery/>
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-grey-10);
  nav {
    background: var(--bcg-clr-primary);
  }
`

export default GalleryPage
